body {
    font-family: 'Roboto', sans-serif !important;
}

.main-container {
    padding: 4rem;
}

h1, h2, h3, h4, h5, h6 {
    color: #BABABA;
}

a {
    color: #1876D2;
}

@media screen and (max-width: 600px) {
    .hide-mobile {
        display: none !important;
    }
}

.rating {
    font-size: 12px;
    margin-left: 0.5rem;
}

p {
    color: #BABABA;
}

table {
    box-shadow: 10px 10px inset #2a2927;
}

.tabell-sort-info {
    display: flex;
}

.table{
    margin-top: 20px;
    color: #BABABA;
}

.table thead {
    background:#111;
    color:#BABABA;
    border-bottom: 3px solid #6D6C6A;
}

.table tr th,.table tr td{
    text-align:center;
    border-top: none;
}

.table tr {
    background-color: #383632;
}

.table tr:nth-child(even) {
    background-color: #272521;
}

.table tr:nth-child(1) {
    text-align: left;
}

.table td:first-child {
    text-align: center;
}

li {
    list-style-type: none;
    color: #BABABA;
}

ul {
    padding: 0;
}

.margin-top-2 {
    margin-top: 2rem;
}

.regelliste {
    li {
        margin-left: 2rem;
        list-style-type: square;
    }
}

.turneringspoeng-chart {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.antall-spillere-chart {
    padding-bottom: 2rem;
}

.rankingtrend-icon {
    margin-left: 10px;
}

.footer {
    a {
        color: #212529
    }
    font-size:12px;
    text-align: center;

    .fa {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .fa:hover {
        text-decoration: none;
    }
}

.poenginfo {
  color: #BABABA;
}

.achievement-container {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}

.achievements {
    margin-top: 3rem;
}

.achievement-element {
    height: 128px;
    margin: 2rem;
}

.streakIcon {
    margin-bottom: 10px;
}

.streak-20 {
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: pulsered 2s linear 1s infinite;
    animation: pulsered 2s linear 1s infinite;
    box-shadow: 0 0 8px #eb4034, inset 0 0 8px #eb4034;
}

.lyn, .streak, .fischersjakk, .bullet, .total, .deltager, .partier {
    margin-bottom: -10px;
    display: inline-block;

    h4 {
        color: white;
        display: inline;
    }
}

.partier {
    img {
        background-color: white;
        margin-left: 5px;
        margin-bottom: 5px;
    }
}

.bullet {
    img {
        margin-bottom: 5px;
        margin-left: -5px;
    }
}

.deltager {
    img {
        margin-bottom: 8px;
        margin-left: 3px;
    }
}

.total {
    img {
        margin-left: 2px;
        margin-bottom: 5px;
    }
}

.fischersjakk {
    img {
        margin-left: 2px;
    }
}

.lyn {
    img {
        margin-left: -5px;
    }
}

.perfs {
    margin-bottom: 1rem;
    background-color: #2a2927;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    justify-items: start;
}

.card-body {
    background-color: #2a2927;
    margin-bottom: 1rem;
}

.perfchild {
    padding: 1rem;
    user-select: none;
}

.ikoner {
    display: inline;
    margin-top: 0.5rem;
}

.achievement-element {
    transition: transform .2s; /* Animation */
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
    background-color: #2a2927;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
  user-select: none;

  .achievement-title {
    font-weight: bold;
    position: relative;
    z-index: 10;
    margin-left: -10px;
    width: 120px;
    text-align: center;
    border-radius: 5px;
    margin-top: 15px;
    color: black;
    background-color: #BABABA;
  }

  .achieved {
    background-color: #0dba23
  }

  .achievement-description {
    width: 130px;
    font-size: 12px;
    display: none;
    text-align: start;
  }

  img {
    position: relative;
    z-index: 11;
    margin-left: 20px;
    margin-top: -15px;
  }

  img.nope {
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
    -webkit-filter: grayscale(100%); /* New WebKit */
    filter: grayscale(100%); /* Current draft standard */
  }

    #multitalent {
      margin-top: -20px;
    }
}

.achievement-element:hover .achievement-description {
    display:block;
}


.achievement-element:hover {
    transform: scale(1.15);
}

.player-username {
    display: inline-block;
}

.navn {
    display: inline-block;
    color: #BABABA;
    padding-left: 1rem;
    font-style: italic;
}

.online {
    color: #0dba23;
}



.live-turneringer {
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 1rem 1rem 0.5rem 1rem;
    background-color: #2a2927;

    .glowing-circle {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: #45FF00;
        display: inline-block;
        -webkit-animation: pulse 2s linear 1s infinite;
        animation: pulse 2s linear 1s infinite;
        box-shadow: 0 0 8px #45FF00, inset 0 0 8px #45FF00;
    }

    h3 {
        margin-left: 1rem;
        display: inline-block;
    }
}

.kommende-turneringer {
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 1rem 1rem 0.5rem 1rem;
    background-color: #2a2927;
    color: #BABABA;
}
  

.featured-games {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@keyframes pulse {
    0% { box-shadow:0 0 8px #45FF00, inset 0 0 8px #45FF00; }
    50% { box-shadow:0 0 16px #45FF00, inset 0 0 14px #45FF00; }
    100% { box-shadow:0 0 8px #45FF00, inset 0 0 8px #45FF00; }
  }


@-webkit-keyframes pulse {
    0% { box-shadow:0 0 8px #45FF00, inset 0 0 8px #45FF00; }
    50% { box-shadow:0 0 32px #45FF00, inset 0 0 14px #45FF00; }
    100% { box-shadow:0 0 8px #45FF00, inset 0 0 8px #45FF00; }
  }

  @keyframes pulsered {
    0% { box-shadow:0 0 8px #eb4034, inset 0 0 8px #eb4034; }
    50% { box-shadow:0 0 16px #eb4034, inset 0 0 14px #eb4034; }
    100% { box-shadow:0 0 8px #eb4034, inset 0 0 8px #eb4034; }
  }


@-webkit-keyframes pulsered {
    0% { box-shadow:0 0 8px #eb4034, inset 0 0 8px #eb4034; }
    50% { box-shadow:0 0 32px #eb4034, inset 0 0 14px #eb4034; }
    100% { box-shadow:0 0 8px #eb4034, inset 0 0 8px #eb4034; }
  }
